import axios from 'axios';

const BASE_URL = 'https://tickpanel.digiscorp.com/api';
// const BASE_URL_LOCAL = 'http://localhost:5000/api';

const usedUrl = BASE_URL;

export const getData = async (type, userEmail, currentDate) => {
  const receivedData = await axios({
    method: 'post',
    url: usedUrl,
    data: {
      type,
      date: currentDate,
      userEmail,
    },
  });

  return receivedData.data;
};

export const postEntries = async (data) => {
  try {
    await axios({
      method: 'post',
      url: `${usedUrl}/entries/`,
      data: data,
    });
  } catch (error) {
    console.log(error);
  }
};

export const refreshUnloggedUsers = async (data) => {
  try {
    const response = await axios({
      method: 'post',
      url: `${usedUrl}/getUnlogged`,
      data,
    });

    return response.data;
  } catch (error) {
    console.log(error);
  }
};
